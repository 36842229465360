.ratio {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: "";
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-4x3 {
  --aspect-ratio: calc(4 / 3 * 100%);
}

.ratio-3x4 {
  --aspect-ratio: calc(3 / 4 * 100%);
}
