.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.flex-column {
  flex-direction: column;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.border {
  border: 1px solid #f0f0f0;
}

.border-top {
  border-top: 1px solid #f0f0f0;
}

.border-bottom {
  border-bottom: 1px solid #f0f0f0;
}

.border-vertical {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
