@import "~antd/dist/antd.css";

@import "includes/ratio";
@import "includes/utility";

// .site-layout {
//   min-height: calc(100vh - 64px);
//   margin: 64px 0 0 0;
// }

// .site-layout-header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0px 24px;
//   position: fixed;
//   z-index: 1;
//   width: 100%;
// }

// .site-layout-sidebar {
//   overflow: auto;
//   height: 100vh;
//   position: fixed;
//   left: 0;
//   margin-top: 64px;
// }

.ant-card-bordered .ant-card-cover {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.site-layout-main {
  height: 100vh;

  .site-layout-background {
    background: #fff;
  }

  .ant-layout-sider {
    padding-top: 60px;
  }
}

.site-layout-content {
  margin: 0 24px 24px;
  padding: 16px 24px;
  background: #fff;
}

.site-layout-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 24px;
  // position: fixed;
  // z-index: 1;
  width: 100%;
}
